import { createAsyncThunk } from "@reduxjs/toolkit";
import { multitenantRoutes } from "../../../Constants/api";
import { getRequest, postRequest } from "Utilities/ApiHelpers/ApiHelpers.index";
import { IUser } from "./types";

export const getAllUsers = createAsyncThunk(
  multitenantRoutes.getOrganizations,
  async () => {
    const response = await getRequest(multitenantRoutes.GetAllUsers);
    return response.data;
  }
);

export const addUser = createAsyncThunk(
  multitenantRoutes.organizations,
  async (user: IUser) => {
    const response = await postRequest(
      multitenantRoutes.AddUser,
      null,
      true,
      user,
      null
    );
    return response.data;
  }
);

//TODO: Needs to be update
export const updateUser = createAsyncThunk(
  multitenantRoutes.organizations,
  async (user: IUser) => {
    const response = await postRequest(
      multitenantRoutes.AddUser,
      null,
      true,
      user,
      null
    );
    return response.data;
  }
);

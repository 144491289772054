import { Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import "Features/Tenants/tenants.index.css";
import { Platform } from "Features/Users/redux/types";

export const renderActiveStatus = (isActive: boolean) => 
  isActive ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;

const getPlatformDisplayName = (platform: string): string => {
  switch (platform) {
    case Platform.TenantManagementPortal:
      return "Tenant Management Portal";
    case Platform.SelfServicePortal:
      return "Self Service Portal";
    default:
      return "Centerprise";
  }
};

export const columns: ColumnsType<any> = [
  {
    title: "Username",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "Email",
    dataIndex: "emailAddress",
    key: "emailAddress",
  },
  {
    title: "Active",
    dataIndex: "isLockedOut",
    key: "isLockedOut",
    render: (isLockedOut: boolean) => renderActiveStatus(!isLockedOut),
  },
  {
    title: "Platform",
    dataIndex: "platform",
    key: "platform",
    align: "center",
    render: getPlatformDisplayName,
  },
];

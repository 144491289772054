export type DBInformationState = {
  providerList: any[];
  isDbModalOpen : boolean;
  databases : string[];
};

export interface IGetDatabasesRequestDTO {
  dataProvider: DataProviderType | string; // Assuming DataProviderType is represented as a string
  database: string;
  password: string;
  port: number;
  schema: string;
  server: string;
  user: string;
}

export enum DataProviderType {
  SqlServer = 0,
  Oracle = 1,
  OracleODPNet = 2,
  OracleODPNetManaged = 3,
  DB2IBM = 4,
  DB2 = 5,
  MSAccess = 6,
  Sybase = 7,
  MySql = 8,
  ODBC = 9,
  SqlServerCe = 10,
  SqlServerCe4 = 11,
  Salesforce = 12,
  NullProvider = 13,
  Netezza = 14,
  Teradata = 15,
  DynamicsCrm = 16,
  Ado = 17,
  Oledb = 18,
  Sqlite = 19,
  PostgreSql = 20,
  Adm = 21,
  SAP = 22,
  Tableau = 23,
  Redshift = 24,
  OData = 25,
  SapHana = 26,
  MariaDb = 27,
  Snowflake = 28,
  Vertica = 29,
  SalesforceLegacy = 30,
  AmazonAuroraMySql = 31,
  AmazonAuroraPostgreSql = 32,
  AzureSql = 33,
  GoogleBigQuery = 34,
  SalesforceRestV1 = 35,
  SapSqlAnywhere = 36,
  AzureSynapse = 37
}


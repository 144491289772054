import { msalInstance } from "index";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { azureScope } from "Config/authConfig";
import LocalStorageUtility from "Utilities/localstorage";

const TOKEN_EXPIRY_BUFFER = 5 * 60 * 1000; // 5 minutes

export const acquireToken = async (): Promise<string | void> => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length === 0) {
    LocalStorageUtility.deleteUserInfoAndToken();
    window.location.href = "/";
    return;
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: azureScope,
      account: accounts[0],
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      LocalStorageUtility.deleteUserInfoAndToken();
      window.location.href = "/";
    } else {
      console.error("Token acquisition error:", error);
      throw error;
    }
  }
};

export const isTokenExpiringSoon = () => {
  const authInfo = LocalStorageUtility.getUserInfoAndToken();
  const expiryTime = authInfo?.token?.expiresAt;

  if (!expiryTime) return true;

  const currentTime = Date.now();
  const tokenExpiryTime = parseInt(expiryTime, 10);
  return tokenExpiryTime - currentTime <= TOKEN_EXPIRY_BUFFER;
};

import { Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { AddEditFormFieldType, IOrganization } from "../redux/types";
import { useAppDispatch, useAppSelector } from "Store/hooks";
import { selectCurrentOrganization, selectIsModalOpen } from "../redux/selectors";
import { setIsModalOpen } from "../redux/slice";
import { OrganizationConstants } from "Constants";

const AddEditOrganization = ({ saveEditOrganization }: any) => {
  const dispatch = useAppDispatch();
  const currentOrganization = useAppSelector(selectCurrentOrganization);
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const [form] = useForm();

  const onFinish = (values: any) => {
    const payload: IOrganization = {
      ...values,
      userId : null,
      createdBy: "",
      updatedBy: "",
      createDtTm: new Date(),
      updateDtTm: new Date(),
      status: "",
      errorInfo: null,
      isActive: true,
      name: values.name,
      id: currentOrganization.id,
    };
    saveEditOrganization(payload);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    dispatch(setIsModalOpen(false));
  };
  return (
    <Modal
      title={
        currentOrganization.id === "-1"
          ? OrganizationConstants.ADD_ORGANIZATION
          : OrganizationConstants.UPDATE_ORGANIZATION_TEXT
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={
        currentOrganization.id === "-1"
          ? OrganizationConstants.ADD_ORGANIZATION
          : OrganizationConstants.UPDATE_ORGANIZATION_TEXT
      }
    >
      <Form
        style={{marginTop:'1rem'}}
        name="basic"
        initialValues={currentOrganization}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item<AddEditFormFieldType>
          label="Organization Name"
          name="name"
          rules={[
            { required: true, message: OrganizationConstants.NAME_VALIDATION },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditOrganization;

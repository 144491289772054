import { useState } from "react";
import { useAppDispatch } from "Store/hooks";
import { AuthenticateAzureUser } from "../redux/thunks";
import { useNavigate } from "react-router-dom";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import MicrosoftIcon from "Assets/svgs/Microsoft.svg";
import { useMsal } from "@azure/msal-react";
import { azureScope } from "Config/authConfig";
import { Platform } from "Features/Users/redux/types";
import "../Auth.index.css";
import { Button } from "antd";
import LocalStorageUtility from "Utilities/localstorage";
import { ILocalStorageInfo } from "Common/types";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);

  const authenticateUser = async () => {
    try {
      setIsLoading(true);
      const azureAuthResult = await instance.loginPopup({
        scopes: azureScope,
        prompt: process.env.NODE_ENV === "production" ? "login" : undefined,
      });
      
      const authenticatedUser = await authenticateUserOnServer(
        azureAuthResult.accessToken
      );
      saveDataToLocalStorage(azureAuthResult, authenticatedUser);
      navigate("/tenants");
    } catch (err: any) {
      handleAuthenticationError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const authenticateUserOnServer = async (accessToken: string) => {
    return dispatch(
      AuthenticateAzureUser({
        accessToken,
        platform: Platform.TenantManagementPortal,
      })
    ).unwrap();
  };

  const saveDataToLocalStorage = (user: any, authenticatedUser: any) => {
    const expiryTime = Date.now() + (user.expiresOn ? user.expiresOn.getTime() : 0) * 1000;
    const userInfo: ILocalStorageInfo = {
      token: {
        accessToken: user.accessToken,
        tokenType: user.tokenType,
        expiresAt: expiryTime.toString(),
      },
      user: authenticatedUser,
    };
    LocalStorageUtility.setUserInfoAndToken(userInfo);
  };

  const handleAuthenticationError = (err: any) => {
    const errMessage = err?.message?.includes("cancelled")
      ? "Login cancelled"
      : err?.message;
    if (errMessage) {
      dispatch(
        setErrorState({
          message: "Error",
          description: errMessage || "Login failed",
          placement: "topRight",
          type: "error",
        })
      );
    }
    console.error("Login failed:", err);
  };

  return (
    <div className="auth-login-container">
      <div className="login-btn-container">
        <Button
          type="default"
          className="signup-btn"
          onClick={authenticateUser}
          loading={isLoading}
        >
          <img src={MicrosoftIcon} height={20} alt="" />
          <span className="text"> Sign-in with Microsoft</span>
        </Button>
      </div>
    </div>
  );
};
export default Login;

import { useAppDispatch, useAppSelector } from "Store/hooks";
import { useEffect, useState } from "react";
import { getAllDbProviders, getDatabases } from "./redux/thunks";
import { Button, Form, Input, Modal, Popconfirm, Select } from "antd";
import {
  selectDatabases,
  selectDbModalOpen,
  selectProviderList,
} from "./redux/selectors";
import { setDatabasesList, setIsDbModalOpen } from "./redux/slice";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import { useForm } from "antd/es/form/Form";
import { initialValues } from "./initialValues";
import { DataProviderType, IGetDatabasesRequestDTO } from "./redux/types";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { selectCurrentTenant } from "Features/Tenants/redux/selectors";
import { ITenant } from "Features/Tenants/redux/types";
import "./dbInformation.index.css";
import { DBInformationConstants } from "Constants";

const DBInformation = ({ saveEditTenant }: any) => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const dataProviderValue = Form.useWatch("dataProvider", form);
  const isDbModalOpen = useAppSelector(selectDbModalOpen);
  const dbProviderList = useAppSelector(selectProviderList);
  const databases = useAppSelector(selectDatabases);
  const selectedTenant = useAppSelector(selectCurrentTenant);

  const [databaseLoader, setDatabaseLoader] = useState(false);
  const [databaseProviderLoader, setDatabaseProviderLoader] = useState(false);

  const defaultDbInfo: any = {
    user: "",
    password: "",
    server: "",
    database: "",
    port:
      dataProviderValue === "SqlServer"
        ? "1433"
        : dataProviderValue === "PostgreSql"
        ? "5432"
        : "",
  };

  useEffect(() => {
    dispatch(setDatabasesList([]));
    form.setFieldsValue(defaultDbInfo);
  }, [dataProviderValue, form]);

  useEffect(() => {
    getDBProviders();
  }, []);

  const getDBProviders = () => {
    dispatch(setLoadingState(true));
    setDatabaseProviderLoader(true);
    dispatch(getAllDbProviders())
      .unwrap()
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
        setDatabaseProviderLoader(false);
      });
  };
  const handleOk = async (configureRepo: boolean) => {
    try {
      await form.validateFields();
      onFinish(form.getFieldsValue(), configureRepo);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    dispatch(setIsDbModalOpen(false));
  };
  const onFinish = (values: any, configureRepo: boolean) => {
    const payload: ITenant = {
      ...selectedTenant,
      serverDbInfo: {
        ...values,
        dataProvider: DataProviderType[values.dataProvider],
      },
      updateDtTm: new Date(),
    };

    saveEditTenant(payload, configureRepo);
    dispatch(setIsDbModalOpen(false));
  };

  const HandleClickDatabase = async () => {
    const payload = form.getFieldsValue();
    try {
      await form.validateFields(["dataProvider", "user", "password", "server"]);
      payload.dataProvider = DataProviderType[payload.dataProvider];
      fetchDatabases(payload);
    } catch (error: any) {
      console.log(error);
    }
  };
  const fetchDatabases = (payload: IGetDatabasesRequestDTO) => {
    setDatabaseLoader(true);
    dispatch(getDatabases(payload))
      .unwrap()
      .catch((error: any) => {
        dispatch(
          setErrorState({
            description: error.message,
            type: "error",
            placement: "topRight",
            message: "Error",
          })
        );
        console.log(error);
      })
      .finally(() => {
        setDatabaseLoader(false);
      });
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => {
    console.log(
      option,
      input,
      (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
    );
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const OkButtonRenderer = () => {
    return (
      <Popconfirm
        cancelText={"No"}
        okText={"Yes"}
        onConfirm={() => {
          handleOk(true);
        }}
        onCancel={() => {
          dispatch(setLoadingState(false));
          handleOk(false);
        }}
        title={"Do you want to build the repository as well?"}
      >
        <Button type="primary" htmlType="submit">
          {DBInformationConstants.CONNECT_BUTTON_TEXT}
        </Button>
      </Popconfirm>
    );
  };

  return (
    <Modal
      title="Database Information"
      open={isDbModalOpen}
      style={{ top: 20 }}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        style={{ marginTop: "20px" }}
        initialValues={initialValues}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Data Provider"
          name="dataProvider"
          rules={[{ required: true, message: "Please select Data Provider!" }]}
        >
          <Select
            // showSearch
            optionFilterProp="children"
            loading={databaseProviderLoader}
            filterOption={filterOption}
          >
            {dbProviderList.map((obj: any) => {
              return (
                <Select.Option
                  key={obj.providerTypeStr}
                  value={obj.providerTypeStr}
                >
                  {obj.displayName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="User ID"
          name="user"
          rules={[{ required: true, message: "Please input user id!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Server Name"
          name="server"
          rules={[{ required: true, message: "Please input server name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Database"
          name="database"
          rules={[{ required: true, message: "Please select Database!" }]}
        >
          <Select loading={databaseLoader} onFocus={HandleClickDatabase}>
            {databases.map((name: string) => {
              return (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Port"
          name="port"
          rules={[{ required: true, message: "Please input port!" }]}
        >
          <Input
            min={0} // Optionally set minimum value
            type="number"
          />
        </Form.Item>
        <div className="footer-buttons">
          <Form.Item>
            <Button
              style={{ marginRight: "0.5rem" }}
              onClick={() => {
                handleCancel();
                dispatch(setLoadingState(false));
              }}
            >
              {DBInformationConstants.CANCEL_BUTTON_TEXT}
            </Button>
          </Form.Item>
          <Form.Item>
            <OkButtonRenderer />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default DBInformation;

import { IOrganization } from "../redux/types";

export const initialValues : IOrganization = {
  userId : null,
  id: "-1",
  createdBy: "",
  updatedBy: "",
  createDtTm: new Date(),
  updateDtTm: new Date(),
  status: "",
  errorInfo: null,
  isActive: true,
  name: "",
};

import { useAppDispatch } from "Store/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialUser } from "../addEditUser/intialValues";
import { setCurrentUser, setIsUserModalOpen } from "../redux/slice";
import { Button } from "antd";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "Features/Users/users.index.css";

const UsersToolbar = () => {
  const dispatch = useAppDispatch();
  return (
    <div
      style={{
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row-reverse",
        margin: "5px",
        alignItems: "center",
      }}
    >
      <Button
        type="primary"
        className="add-organization-button"
        onClick={() => {
          dispatch(setCurrentUser(initialUser));
          dispatch(setIsUserModalOpen(true));
        }}
      >
        <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faPlus} />
        Add User
      </Button>
      <div className="organization-heading">Users</div>
    </div>
  );
};

export default UsersToolbar;
import { ILocalStorageInfo } from "Common/types";

class LocalStorageUtility {
  public static readonly LOCAL_STORAGE_KEY = "authInfo";

  public static setUserInfoAndToken(value: ILocalStorageInfo) {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(value || {}));
  }
  public static getUserInfoAndToken() {
    const data = JSON.parse(
      localStorage.getItem(this.LOCAL_STORAGE_KEY) ?? "{}"
    );
    return data;
  }
  public static getAccessToken() {
    const data = this.getUserInfoAndToken();
    return data?.token?.accessToken;
  }
  public static setTokenExpiry(value: string) {
    const authInfo = this.getUserInfoAndToken();
    authInfo.token.expiresAt = value;
    this.setUserInfoAndToken(authInfo);
  }
  public static setAccessToken(value: string) {
    const authInfo = this.getUserInfoAndToken();
    authInfo.token.accessToken = value;
    this.setUserInfoAndToken(authInfo);
  }
  public static deleteUserInfoAndToken() {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
  }
}

export default LocalStorageUtility;

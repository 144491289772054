import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "Store/hooks";
import { Button } from "antd";
import { setCurrentOrganization, setIsModalOpen } from "../redux/slice";
import { initialValues } from "../addEditOrganization/intialValues";
import { OrganizationConstants } from "Constants";
import "Features/Organizations/organization.index.css";

const OrganizationToolbar = () => {
  const dispatch = useAppDispatch();
  return (
    <div
    style={{justifyContent:"space-between", display: "flex", flexDirection: "row-reverse", margin: "5px",alignItems:'center' }}
    >
      <Button
        type="primary"
        className="add-organization-button"
        onClick={() => {
          dispatch(setCurrentOrganization(initialValues));
          dispatch(setIsModalOpen(true));
        }}
      >
        <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faPlus} />
        {OrganizationConstants.ADD_ORGANIZATION}
      </Button>
      <div className="organization-heading">
        Organizations
      </div>
    </div>
  );
};

export default OrganizationToolbar;

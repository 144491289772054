import React, { useState } from "react";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./sideNav.index.css";
import { useAppSelector } from "Store/hooks";
import { selectActiveKey } from "Layout/redux/selectors";
import LicenseLogo from "Assets/images/LicenseLogo.png";
import CollapseIcon from "Assets/svgs/Collapse.svg";
import { ReactComponent as OrganizationIcon } from "Assets/svgs/Organization.svg";
import { ReactComponent as SettingsIcon } from "Assets/svgs/Settings.svg";
import LocalStorageUtility from "Utilities/localstorage";
const { Sider } = Layout;

const SideNav = () => {
  const navigate = useNavigate();
  const activeKey = useAppSelector(selectActiveKey);
  const [collapsed, setCollapsed] = useState(false);
  const authInfo = LocalStorageUtility.getUserInfoAndToken()
  const firstName = authInfo?.user?.firstName
  const userName =  authInfo?.user?.userName

  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
  };
  const navigateToRoute = (route: string) => {
    navigate(route);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={() => {
          LocalStorageUtility.deleteUserInfoAndToken();
          window.location.href = "/";
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Sider
      width={250}
      style={{ backgroundColor: "#fff" }}
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      trigger={null}
    >
      {!collapsed ? (
        <div className="demo-logo-vertical">
          <img alt="" src={LicenseLogo} />
          <div>
            <span className="license-heading">Management </span>
            <span className="manager-heading">Portal</span>
          </div>
          <div className="collpase-icon-div">
            <img alt="" src={CollapseIcon}></img>
          </div>
        </div>
      ) : (
        <div className="demo-logo-vertical-collapsed">
          <img alt="" src={LicenseLogo} />
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "inherit",
          height: "calc(100vh - 78px)",
          justifyContent: "space-between",
        }}
      >
        <Menu
          style={{ backgroundColor: "#fff" }}
          selectedKeys={activeKey}
          mode="inline"
        >
          <Menu.Item
            onClick={() => {
              navigateToRoute("/tenants");
            }}
            key="tenants"
            icon={<OrganizationIcon />}
          >
            Tenants
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              navigateToRoute("/organizations");
            }}
            key="organizations"
            icon={<TeamOutlined size={24} style={{ marginRight: "5px" }} />}
          >
            Organizations
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              navigateToRoute("/users");
            }}
            key="users"
            icon={<TeamOutlined size={24} style={{ marginRight: "5px" }} />}
          >
            Users
          </Menu.Item>
        </Menu>
        {collapsed ? (
          <div style={{ padding: "15px", paddingBottom: "10px" }}>
            {" "}
            <Avatar size={40} className="ant-avatar" icon={<UserOutlined />} />
          </div>
        ) : (
          <div className="side-nav-footer">
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <Avatar
                size={40}
                className="ant-avatar"
                icon={<UserOutlined />}
              />
              <div>
                <div className="user-name">
                  {firstName && firstName !== "null"
                    ? firstName
                    : userName && userName !== "null"
                      ? userName
                      : ""}
                </div>
              </div>
            </div>
            <div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <SettingsIcon className="settings-icon" height={30} />
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </Sider>
  );
};

export default SideNav;

import { useAppDispatch, useAppSelector } from "Store/hooks";
import { useEffect } from "react";
import { getAllOrganizations, addOrganization, updateOrganization } from "./redux/thunks";
import { IOrganization, IOrganizationFilter } from "./redux/types";
import OrganizationsTable from "./table/table.index";
import "./organization.index.css";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import OrganizationToolbar from "./toolbar/toolbar.index";
import AddEditOrganization from "./addEditOrganization/addEditOrganization.index";
import { selectIsModalOpen } from "./redux/selectors";
import { setIsModalOpen } from "./redux/slice";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { Card } from "antd";
import { OrganizationConstants } from "Constants";
import { setActiveKey } from "Layout/redux/slice";

const Organizations = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(selectIsModalOpen);

  useEffect(() => {
    dispatch(setActiveKey(["organizations"]))
    getOrganizations();
  }, [dispatch]);

  const getOrganizations = () => {
    let organizationFilter: IOrganizationFilter = {
      name: null
    };
    dispatch(setLoadingState(true));
    dispatch(getAllOrganizations(organizationFilter))
      .unwrap()
      .catch((err: any) => {console.log(err)})
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };
  
  const saveEditOrganization = (values: IOrganization) => {
    dispatch(setLoadingState(true));
    dispatch(values.id === "-1" ? addOrganization(values) : updateOrganization(values))
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: OrganizationConstants.ORGANIZATION_SAVED_MESSAGE,
            placement: "topRight",
            type: "success",
          })
        );
        dispatch(setIsModalOpen(false));
        getOrganizations();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  return (
    <>
      <div className="organization-container">
        <Card style={{height:"100%", border: "1px solid rgba(0, 0, 0, 0.10)" }}>
          <OrganizationToolbar />
          <OrganizationsTable />
        </Card>
        {isModalOpen && <AddEditOrganization saveEditOrganization={saveEditOrganization} />}
      </div>
    </>
  );
};

export default Organizations;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUser, IUserState } from "./types";
import { getAllUsers } from "./thunks";
import { initialUser } from "../addEditUser/intialValues";

const initialState: IUserState = {
  userList: [],
  currentUser: initialUser,
  isUserModalOpen: false,
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserList: (state, action: PayloadAction<IUser[]>) => {
      return {
        ...state,
        userList: action.payload,
      };
    },
    setCurrentUser: (state, action: PayloadAction<IUser>) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    setIsUserModalOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state, 
        isUserModalOpen: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.userList = action.payload;
    });
  },
});

export const { setUserList, setCurrentUser, setIsUserModalOpen } = users.actions;
export default users.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { multitenantRoutes } from "../../../Constants/api";
import {
  deleteRequest,
  postRequest,
  putRequest,
} from "../../../Utilities/ApiHelpers/ApiHelpers.index";
import { IOrganization, IOrganizationFilter } from "./types";
import { v4 as uuidv4 } from 'uuid';

export const getAllOrganizations = createAsyncThunk(
  multitenantRoutes.getOrganizations,
  async (organizationFilter: IOrganizationFilter) => {
    const response = await postRequest(
      multitenantRoutes.getOrganizations,
      null,
      true,
      organizationFilter,
      null
    );
    return response.data;
  }
);

export const addOrganization = createAsyncThunk(
  multitenantRoutes.organizations,
  async (organization: IOrganization) => {
    organization.id = uuidv4();
    const response = await postRequest(
      multitenantRoutes.organizations,
      null,
      true,
      organization,
      null
    );
    return response.data;
  }
);

export const updateOrganization = createAsyncThunk(
  multitenantRoutes.organizations,
  async (organization: IOrganization) => {
    const response = await putRequest(
      multitenantRoutes.organizations,
      null,
      true,
      organization,
      null
    );
    return response.data;
  }
);

export const deleteOrganization = createAsyncThunk(
  multitenantRoutes.organizations,
  async (organizationId : number) => {
    const response = await deleteRequest(
      `${multitenantRoutes.organizations}/${organizationId}`,
      null,
      true,
      null
    );
    return response.data;
  }
);

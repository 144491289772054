import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILayout } from "./types";

const initialState: ILayout = {
  heading: "",
  activeKey: ["tenants"],
  subNavData: [],
};

export const layout = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSubNav: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        subNavData: action.payload.navData,
        heading: action.payload.heading,
        activeKey: action.payload.activeKey,
      };
    },
    setActiveKey: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        activeKey: action.payload,
      };
    },
    setHeading: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        heading: action.payload,
      };
    },
  },
});
export const { setSubNav, setHeading, setActiveKey } = layout.actions;
export default layout.reducer;

import moment from "moment";
import "Features/Tenants/tenants.index.css";

export const LicenseInfoRenderer = (_: any, record: any) => {
  const parseDateTime = () => {
    try {
      const momentObj = moment(record.license.expirationDate);
      const formattedDateTime = momentObj.format("MMMM Do YYYY, h:mm:ss a");
      return formattedDateTime;
    } catch {}
  };

  if (!record.license) return "N/A";

  return (
    <> {record.license && `${record.license.version} - ${parseDateTime()}`}</>
  );
};

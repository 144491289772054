import { createAsyncThunk } from "@reduxjs/toolkit";
import { dbAgentRoutes } from "Constants/api";
import { getRequest, postRequest } from "Utilities/ApiHelpers/ApiHelpers.index";
import { IGetDatabasesRequestDTO } from "./types";

export const getAllDbProviders = createAsyncThunk(
  dbAgentRoutes.dbProviders,
  async () => {
    const response = await getRequest(dbAgentRoutes.dbProviders, null, true, true);
    return response.data;
  }
);

export const getDatabases = createAsyncThunk(
  dbAgentRoutes.getDabases,
  async (dataBaseInfo: IGetDatabasesRequestDTO) => {
    const response = await postRequest(
      dbAgentRoutes.getDabases,
      null,
      true,
      dataBaseInfo,
      null,
      true
    );
    return response.data;
  }
);

import { DataProviderType, IGetDatabasesRequestDTO } from "./redux/types";

export const initialValues : IGetDatabasesRequestDTO = {
    dataProvider: "SqlServer",
    database: "",
    password: "",
    port: 1433,
    schema: "",
    server: "",
    user: ""
}
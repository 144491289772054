import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ITenantState } from "./types";
import { getAllTenants } from "./thunks";
import { initialValues } from "../addEditTenant/intialValues";

const initialState: ITenantState = {
  tenantList: [],
  currentTenant: initialValues,
  isModalOpen: false,
};

export const tenant = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    setTenantList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        tenantList: action.payload,
      };
    },
    setCurrentTenant: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentTenant: action.payload,
      };
    },
    setIsModalOpen: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isModalOpen: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllTenants.fulfilled, (state, action) => {
      state.tenantList = action.payload;
    });
  },
});
export const { setTenantList, setCurrentTenant,setIsModalOpen } = tenant.actions;
export default tenant.reducer;

import {
  faCheck,
  faEllipsis,
  faPencilAlt,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "Features/Tenants/tenants.index.css";
import { useAppDispatch } from "Store/hooks";
import { setCurrentOrganization, setIsModalOpen } from "../../redux/slice";
import {
  deleteOrganization,
  getAllOrganizations,
  addOrganization,
  updateOrganization,
} from "../../redux/thunks";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { Dropdown, MenuProps, Popconfirm } from "antd";
import { IOrganization, IOrganizationFilter } from "../../redux/types";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import { setIsDbModalOpen } from "Features/DBInformation/redux/slice";
import "Features/Tenants/tenants.index.css";
import { OrganizationConstants } from "Constants";

export const ActionRenderer = (_: any, record: any) => {
  const editTenant = (values: IOrganization) => {
    dispatch(setLoadingState(true));
    dispatch(
      values.id === "-1" ? addOrganization(values) : updateOrganization(values)
    )
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: OrganizationConstants.ORGANIZATION_SAVED_MESSAGE,
            placement: "topRight",
            type: "success",
          })
        );
        dispatch(setIsModalOpen(false));
        getOrganizations();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };
  const getOrganizations = () => {
    let organizationFilter: IOrganizationFilter = {
      name: null,
    };
    dispatch(setLoadingState(true));
    dispatch(getAllOrganizations(organizationFilter))
      .unwrap()
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };
  const dispatch = useAppDispatch();

  const activateOrganization = () => {
    editTenant({ ...record, isActive: true });
  };

  const deactivateOrganization = () => {
    editTenant({ ...record, isActive: false });
  };

  const deleteSelectedOrganization = () => {
    dispatch(setLoadingState(true));
    dispatch(deleteOrganization(record.id))
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: OrganizationConstants.ORGANIZATION_DELETD_MESSAGE,
            placement: "topRight",
            type: "success",
          })
        );
        getOrganizations();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  const handleDelete = () => {
    deleteSelectedOrganization();
  };

  const handleEdit = () => {
    dispatch(setCurrentOrganization(record));
    dispatch(setIsModalOpen(true));
  };

  const handleActivation = () => {
    !record.isActive && activateOrganization();
  };
  const handleDeactivation = () => {
    record.isActive && deactivateOrganization();
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div onClick={handleEdit} className={"action-items"}>
          <FontAwesomeIcon
            className="action-items-icon"
            color="#4fa3ec"
            icon={faPencilAlt}
          />{" "}
          {OrganizationConstants.EDIT_ORGANIZATION_TEXT}{" "}
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          className={"action-items"}
          onClick={handleActivation}
          style={{ opacity: record.isActive ? 0.5 : 1 }}
        >
          <FontAwesomeIcon
            className="action-items-icon"
            color="green"
            icon={faCheck}
            title=""
          />
          {OrganizationConstants.ACTIVATE_ORGANIZATION_TEXT}
        </div>
      ),
      key: "4",
    },
    {
      label: (
        <div
          className={"action-items"}
          onClick={handleDeactivation}
          style={{ opacity: !record.isActive ? 0.5 : 1 }}
        >
          <FontAwesomeIcon
            className="action-items-icon"
            color="red"
            icon={faTimes}
          />
          {OrganizationConstants.DEACTIVATE_ORGANIZATION_TEXT}
        </div>
      ),
      key: "5",
    },
    {
      label: (
        <Popconfirm
          title={OrganizationConstants.ORGANIZATION_DELETION_TITLE_TEXT}
          description={
            OrganizationConstants.ORGANIZATION_DELETION_VERIFICATION_TEXT
          }
          onConfirm={handleDelete}
        >
          <div className={"action-items action-items-red"}>
            <FontAwesomeIcon
              className="action-items-icon action-items-icon-red"
              icon={faTrashAlt}
            />
            {OrganizationConstants.DELETE_ORGANIZATION_TEXT}
          </div>
        </Popconfirm>
      ),
      danger: true,
      key: "2",
    },
  ];
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          icon={faEllipsis}
          rotation={90}
        />
      </Dropdown>
    </div>
  );
};

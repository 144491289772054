import { createAsyncThunk } from "@reduxjs/toolkit";
import { multitenantRoutes } from "../../../Constants/api";
import { getRequest, postRequest } from "../../../Utilities/ApiHelpers/ApiHelpers.index";
import { LoginModel } from "./types";

export const postLogin = createAsyncThunk(
  multitenantRoutes.login,
  async (loginPayload: LoginModel) => {
  const response = await postRequest(multitenantRoutes.login, null, false, loginPayload, null);
  return response.data;
  });

export const logout = createAsyncThunk(
  multitenantRoutes.logout,
  async () => {
      const response = await getRequest(multitenantRoutes.logout);
      return response.data;
  }
);

export const checkIfMultiTenantDbExists = createAsyncThunk<boolean>(
  multitenantRoutes.checkMultiTenantDb,
  async () => {
      const response = await getRequest(multitenantRoutes.checkMultiTenantDb);
      return response.data;
  }
);

export const setupMultiTenantDbAndDefaultTenant = createAsyncThunk(
  multitenantRoutes.checkMultiTenantDb,
  async () => {
      const response = await getRequest(multitenantRoutes.setupMultiTenantDbAndDefaultTenant);
      return response.data;
  }
);

export const AuthenticateAzureUser = createAsyncThunk(
  multitenantRoutes.AuthenticateAzureUser,
  async (params: any) => {
    const response = await getRequest(
      multitenantRoutes.AuthenticateAzureUser,
      params,
      true
    );
    return response.data;
  }
);

export const AuthConstants = {
    AUTH_LOGO_TEXT : "Manage all your Data in a Unified Space without writing any Code",
    LOGIN_HEADING_TEXT : "User Login",
    LOGIN_BUTTON_TEXT : "Login",
    LOGIN_BUTTON_LOADING_TEXT : "Loading..."
}  

export const DBInformationConstants = {
    CANCEL_BUTTON_TEXT : "Cancel",
    CONNECT_BUTTON_TEXT : "Connect"
}

export const TenantConstants = {
    CREATE_TENANT_TEXT : "Create Tenant",
    UPDATE_TENANT_TEXT : "Update Tenant",
    ADD_TENANT : "Add Tenant",
    TENANT_SAVED_MESSAGE : "Tenant saved successfully"
}

export const OrganizationConstants = {
    CREATE_ORGANIZATION_TEXT : "Create Organization",
    UPDATE_ORGANIZATION_TEXT : "Update Organization",
    ADD_ORGANIZATION : "Add Organization",
    ORGANIZATION_SAVED_MESSAGE : "Organization saved successfully",
    ORGANIZATION_DELETD_MESSAGE : "Organization deleted successfully",
    NAME_VALIDATION : "Please input your organization name!",
    ORGANIZATION_DELETION_VERIFICATION_TEXT : "You won't be able to recover deleted organization and respective tenants",
    ORGANIZATION_DELETION_TITLE_TEXT : "Are you sure?",
    DELETE_ORGANIZATION_TEXT : "Delete",
    DEACTIVATE_ORGANIZATION_TEXT : "Deactivate",
    ACTIVATE_ORGANIZATION_TEXT : "Activate",
    EDIT_ORGANIZATION_TEXT : "Edit"

}
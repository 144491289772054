import moment from "moment";

export const DateRenderer = (_: any, record: any) => {
  const parseDateTime = () => {
    try {
      const momentObj = moment(_);
      const formattedDateTime = momentObj.format("MMMM Do YYYY, h:mm:ss a");
      return formattedDateTime;
    } catch {}
  };
  return <> {parseDateTime()}</>;
};

import Tenants from "Features/Tenants/tenants.index";
import Authentication from "../Features/Auth";
import MainLayout from "Layout/layout.index";
import { IPrivateRoutes } from "./types";
import Organizations from "Features/Organizations/organization.index";
import Users from "Features/Users/users.index";

export const PRIVATE_ROUTES : IPrivateRoutes[] = [
	{
		path : '/tenants',
		element : Tenants,
		layout : MainLayout
	},
	{
		path : '/organizations',
		element : Organizations,
		layout : MainLayout
	},
	{
		path : '/users',
		element : Users,
		layout : MainLayout
	},
];

export const PUBLIC_ROUTES = [
	{
		path: "/",
		element: Authentication,
	}
];

export type IUserState = {
  userList: IUser[];
  currentUser: IUser;
  isUserModalOpen: boolean;
};

export enum Platform {
    Centerprise = "Centerprise",
    DeveloperPortal = "DeveloperPortal",
    SelfServicePortal = "SelfServicePortal",
    TenantManagementPortal = "TenantManagementPortal"
}

export enum UserAuthenticationProvider {
    Centerprise = "Centerprise",
    WindowsAuthentication = "WindowsAuthentication",
    AzureAuthentication = "AzureAuthentication"
}

export interface IUser {
  id?: string;
  name: string;
  userName: string;
  emailAddress: string;
  normalizedEmailAddress: string;
  isActive: boolean;
  platform: Platform;
  emailConfirmed: boolean;
  isLockedOut: boolean;
  xmlData: string | null;
  UserAuthenticationProvider: UserAuthenticationProvider
}
import { useAppDispatch, useAppSelector } from "Store/hooks";
import { useEffect } from "react";
import { getAllTenants, saveTenant, updateTenant } from "./redux/thunks";
import { ITenantFilter, SaveTenantType } from "./redux/types";
import TenantsTable from "./table/table.index";
import "./tenants.index.css";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import TenantToolbar from "./toolbar/toolbar.index";
import AddEditTenant from "./addEditTenant/addEditTenant.index";
import { selectIsModalOpen } from "./redux/selectors";
import { setIsModalOpen } from "./redux/slice";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import DBInformation from "Features/DBInformation/dbInformation.index";
import { selectDbModalOpen } from "Features/DBInformation/redux/selectors";
import { Card } from "antd";
import { TenantConstants } from "Constants";
import { setActiveKey } from "Layout/redux/slice";
import { IOrganizationFilter } from "Features/Organizations/redux/types";
import { getAllOrganizations } from "Features/Organizations/redux/thunks";

const Tenants = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const isDbModalOpen = useAppSelector(selectDbModalOpen);

  useEffect(() => {
    dispatch(setActiveKey(["tenants"]));
    getTenants();
    getOrganizations();
  }, []);

  const getOrganizations = () => {
    let organizationFilter: IOrganizationFilter = {
      name: null,
    };
    dispatch(setLoadingState(true));
    dispatch(getAllOrganizations(organizationFilter))
      .unwrap()
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const getTenants = () => {
    let tenantFilter: ITenantFilter = {
      name: null
    };
    dispatch(setLoadingState(true));
    dispatch(getAllTenants(tenantFilter))
      .unwrap()
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };
  const saveEditTenant = (
    values: SaveTenantType,
    configureRepo: boolean = false
  ) => {
    dispatch(setLoadingState(true));

    dispatch(
      values.tenant.id === -1
        ? saveTenant(values)
        : updateTenant({ tenant: values, configureRepo })
    )
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: TenantConstants.TENANT_SAVED_MESSAGE,
            placement: "topRight",
            type: "success",
          })
        );
        dispatch(setIsModalOpen(false));
        getTenants();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message || err,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  return (
    <div className="tenants-container">
      <Card style={{ height: "100%", border: "1px solid rgba(0, 0, 0, 0.10)" }}>
        <TenantToolbar />
        <TenantsTable />
      </Card>
      {isModalOpen && <AddEditTenant saveEditTenant={saveEditTenant} />}
      {isDbModalOpen && <DBInformation saveEditTenant={saveEditTenant} />}
    </div>
  );
};

export default Tenants;

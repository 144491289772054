import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IOrganizationState } from "./types";
import { getAllOrganizations } from "./thunks";
import { initialValues } from "../addEditOrganization/intialValues";

const initialState: IOrganizationState = {
  organizationList: [],
  currentOrganization: initialValues,
  isModalOpen: false,
};

export const organization = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganizationList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        organizationList: action.payload,
      };
    },
    setCurrentOrganization: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrganization: action.payload,
      };
    },
    setIsModalOpen: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isModalOpen: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllOrganizations.fulfilled, (state, action) => {
      state.organizationList = action.payload;
    });
  },
});
export const { setOrganizationList, setCurrentOrganization, setIsModalOpen } = organization.actions;
export default organization.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postLogin } from "./thunks";

import { AuthenticationStateType } from "./types";

const initialState: AuthenticationStateType = {
  authState: "server",
  loginState: 0,
};

export const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        authState: action.payload,
      };
    },
    setLoginState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loginState: action.payload,
      };
    },
    setLoadingState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(postLogin.fulfilled, (state, action) => {
      const name = action.payload?.name;
      const nameArray = name ? name.split(" ") : [];
      const firstName = nameArray.length > 0 ? nameArray[0] : undefined;

      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("user_name", action.payload.userName);
      localStorage.setItem("user_roles", action.payload.roles);
      localStorage.setItem("first_name", firstName)
    });
  },
});
export const { setAuthState, setLoginState, setLoadingState } =
  authentication.actions;
export default authentication.reducer;

import { ColumnsType } from "antd/es/table";
import { DateRenderer } from "./dateRenderer";
import { ActionRenderer } from "./actionRenderer";
import "Features/Tenants/tenants.index.css";

export const columns: ColumnsType<any> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "20%",
  },
  {
    title: "Created On",
    dataIndex: "createDtTm",
    key: "createDtTm",
    render: DateRenderer,
    width: "25%",
  },
  {
    title: "Updated On",
    dataIndex: "updateDtTm",
    key: "updateDtTm",
    render: DateRenderer,
    width: "25%",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width :"10%",
    align: "center",
    render: ActionRenderer,
  },
];

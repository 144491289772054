import { IUser, UserAuthenticationProvider, Platform } from "../redux/types";

export const initialUser: IUser = {
  id: "-1",
  name: "",
  userName: "",
  emailAddress: "",
  normalizedEmailAddress: "",
  isActive: true,
  platform: Platform.TenantManagementPortal,
  emailConfirmed: true,
  isLockedOut: false,
  xmlData: null,
  UserAuthenticationProvider: UserAuthenticationProvider.AzureAuthentication
};

import { useAppDispatch, useAppSelector } from "Store/hooks";
import { useEffect } from "react";
import "./users.index.css";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { Card } from "antd";
import { setActiveKey } from "Layout/redux/slice";
import UsersToolbar from "./toolbar/toolbar.index";
import UsersTable from "./table/table.index";
import { addUser, getAllUsers, updateUser } from "./redux/thunks";
import AddEditUser from "./addEditUser/addEditUser.index";
import { selectIsUserModalOpen } from "./redux/selectors";
import { IUser } from "./redux/types";
import { setIsUserModalOpen } from "./redux/slice";

const User = () => {
  const dispatch = useAppDispatch();
  const isUserModalOpen = useAppSelector(selectIsUserModalOpen);

  const saveEditUser = (values: IUser) => {
    dispatch(setLoadingState(true));
    dispatch(values.id === "-1" ? addUser(values) : updateUser(values))
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: `User added successfully. The user ${values.emailAddress} can now access the Tenant Management Portal.`,
            placement: "topRight",
            type: "success",
          })
        );
        dispatch(setIsUserModalOpen(false));
        getUsersList();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message || "Failed to save user",
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  const getUsersList = async () => {
    try {
      dispatch(setLoadingState(true));
      await dispatch(getAllUsers()).unwrap();
    } catch (err: any) {
      console.error(err);
      dispatch(
        setErrorState({
          message: "Error",
          description: err.message,
          placement: "topRight",
          type: "error",
        })
      );
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  useEffect(() => {
    dispatch(setActiveKey(["users"]));
    getUsersList();
  }, [dispatch]);

  return (
    <div className="organization-container">
      <Card style={{ height: "100%", border: "1px solid rgba(0, 0, 0, 0.10)" }}>
        <UsersToolbar />
        <UsersTable />
      </Card>
      {isUserModalOpen && <AddEditUser saveEditUser={saveEditUser} />}
    </div>
  );
};

export default User;

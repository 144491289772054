import MainHeader from "./Header/header.index";
import { Layout } from "antd";
import React, { ReactElement } from "react";
import SideNav from "./SideNav/sideNav.index";

const { Content } = Layout;

const MainLayout = ({ children }: any): ReactElement => {
  return (
    <>
      {/* //<MainHeader /> */}
      <Layout style={{background:"#EEF1F6", minHeight: "100vh" }}>
        <SideNav />
        <Layout className="site-layout">
          <Content style={{background:"#EEF1F6"}}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;

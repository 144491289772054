import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';
import AuthenticationReducer from '../Features/Auth/redux/slice';
import FullPageLoaderReducer from '../Components/Basic/full_page_loader/redux/slice';
import ErrorBoundaryReducer from '../Components/Basic/ErrorBoundary/redux/slice';
import LayoutReducer from '../Layout/redux/slice';
import TenantReducer from "Features/Tenants/redux/slice";
import DbInformationReducer from 'Features/DBInformation/redux/slice';
import OrganizationReducer from 'Features/Organizations/redux/slice';
import UsersReducer from 'Features/Users/redux/slice';


// Root Reducer
export default function RootReducer(history: History){
  const combinedReducer = combineReducers({
    AuthenticationReducer,
    FullPageLoaderReducer,
    ErrorBoundaryReducer,
    LayoutReducer,
    TenantReducer,
    DbInformationReducer,
    OrganizationReducer,
    UsersReducer
  });

  return (state: any, action: any) => {
    return combinedReducer(state, action);
  };
};

export const defaultUrl = process.env.REACT_APP_SERVER_URL
export const serverUrl = process.env.REACT_APP_LISTENER_URL

export const multitenantBaseURL = "/MultiTenant";

export const multitenantRoutes = {
  login : `${multitenantBaseURL}/Login`,
  logout : `${multitenantBaseURL}/Logout`,  
  allTenants : `${multitenantBaseURL}/AllTenantsWithOrganization`,
  saveTenant : `${multitenantBaseURL}/TenantInfo`,
  updateTenant : `${multitenantBaseURL}/UpdateTenantInfo`,
  deleteTenant : `${multitenantBaseURL}/DeleteTenantInfo`,
  getOrganizations : `${multitenantBaseURL}/GetOrganizations`,
  organizations : `${multitenantBaseURL}/Organization`,
  checkMultiTenantDb : `${multitenantBaseURL}/CheckMultiTenantRepoExistence`,
  setupMultiTenantDbAndDefaultTenant : `${multitenantBaseURL}/AddMultiTenantRepoAndDefaultTenant`,
  GetAllUsers: `${multitenantBaseURL}/GetAllUsers`,
  AddUser: `${multitenantBaseURL}/AddUser`,
  AuthenticateAzureUser: `${multitenantBaseURL}/AuthenticateAzureUser`,
};

export const baseServerApiURL = "api"

export const dbAgentBaseUrl = `${baseServerApiURL}/db`

export const dbAgentRoutes = {
    dbProviders : `${dbAgentBaseUrl}/DbProviders`,
    getDabases : `${dbAgentBaseUrl}/GetDatabases`
}



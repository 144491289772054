import { Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { IUser, Platform, UserAuthenticationProvider } from "../redux/types";
import { useAppDispatch, useAppSelector } from "Store/hooks";
import { selectCurrentUser, selectIsUserModalOpen } from "../redux/selectors";
import { setIsUserModalOpen } from "../redux/slice";

const AddEditUser = ({ saveEditUser }: any) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const isModalOpen = useAppSelector(selectIsUserModalOpen);
  const [form] = useForm();

  const onFinish = (values: IUser) => {
    const payload: IUser = {
      ...values,
      id: currentUser.id,
      xmlData: null,
      UserAuthenticationProvider: UserAuthenticationProvider.AzureAuthentication,
      emailConfirmed: true,
      isLockedOut: false,
      isActive: true,
      normalizedEmailAddress: values.emailAddress.toUpperCase(),
      platform: Platform.TenantManagementPortal,
    };

    saveEditUser(payload);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    dispatch(setIsUserModalOpen(false));
  };
  return (
    <Modal
      title={
        currentUser.id === "-1"
          ? "Add User"
          : "Edit User"
      }
      
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={
        currentUser.id === "-1"
          ? "Add User"
          : "Edit User"
      }
    >
      <p>Add users to authorize access to the <b>Tenant Management Portal</b></p>
      <Form
        style={{marginTop:'1rem'}}
        name="basic"
        initialValues={currentUser}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item<IUser>
          label="Username"
          name="userName"
          rules={[
            { required: true, message: "Username is required" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<IUser>
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Name is required" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<IUser>
          label="Email"
          name="emailAddress"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditUser;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DBInformationState } from "./types";
import { getAllDbProviders, getDatabases } from "./thunks";

const initialState: DBInformationState = {
  providerList: [],
  isDbModalOpen : false,
  databases : []
};

export const dbInformation = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setProviderList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        providerList: action.payload,
      };
    },
    setDatabasesList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        databases: action.payload,
      };
    },
    setIsDbModalOpen: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isDbModalOpen: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllDbProviders.fulfilled, (state, action) => {
      state.providerList = action.payload;
    });
    builder.addCase(getDatabases.fulfilled, (state, action) => {
      state.databases = action.payload;
    });
    builder.addCase(getAllDbProviders.rejected, (state) => {
      state.providerList = [];
    });
    builder.addCase(getDatabases.rejected, (state) => {
      state.databases = [];
    });

  },
});
export const { setProviderList, setIsDbModalOpen,setDatabasesList } = dbInformation.actions;
export default dbInformation.reducer;

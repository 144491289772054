import Logo from "../../Assets/images/centerprise_logo.png";
import "./Auth.index.css";
import moment from "moment";
import Login from "./Login/Login.index";
import AsteraLogo from "Assets/svgs/AsteraLogo.svg";
import LockIcon from "Assets/svgs/Lock.svg";

const Authentication = () => {
  const getComponent = () => {
    return <Login />;
  };

  const getYear = () => {
    return moment().year();
  };

  return (
  <div className="connection_container">
      <div className="header">
        <div className="left_container">
          <img alt="logo" src={AsteraLogo} className="logo" />
          <span className="main_heading">Tenant Management Portal</span>
          <p className="auth_logo_text">Your Central Hub for Tenant Management</p>
        </div>
        <div className="right_container">
          <div className="login_container">
            <div className="connection_wrapper">
              <div className="stepper_container">
                <img src={LockIcon} alt="" />
                <span className="heading">
                Login to Continue
                </span>
              </div>
              <div className="connection_form_wrapper">{getComponent()}</div>
              <div className="privacy-policy-container">
                <img src={AsteraLogo} alt="" />
                <span className="text">Manage your data with zero-code AI interface</span>
                  <span className="sub-text">Privacy Policy | Terms of Use</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <span>{`Copyright (C) ${getYear()} Astera Software. All rights reserved.`}</span>
      </div>
    </div>
  );
};

export default Authentication;
